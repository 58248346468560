import {useState} from 'react';
import {BrowserRouter, Route, Routes, Link} from 'react-router-dom';
import About from './components/About/About';
import Home from './components/Home/Home';
import Search from './components/Search/Search';

import MySkeleton from './components/MySkeleton/MySkeleton';

function App() {
  return (
    <BrowserRouter>
      <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/search" element={<Search />} />
         <Route path="/about" element={<About />} />
         <Route path="/detail/*" element={<About />} />
         <Route path="/skeleton" element={<MySkeleton title="judul" body="badan" />} />
         <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
