import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import "react-loading-skeleton/dist/skeleton.css";



const MySkeleton = (props) => {
  return (
    <>
      <div style={{ fontSize: 20, lineHeight: 2 }}>
        <h1>{props.title || <Skeleton />}</h1>
        <Skeleton count={1} duration={1} width={150} height={150} borderRadius={'100%'} />
        <Skeleton count={1} duration={1} width={150} height={150} />
        {<Skeleton count={2} duration={1} width={400} height={20} />}
      </div>


      <SkeletonTheme baseColor="#ee9999" highlightColor="#ffffff">
          <p>
              <Skeleton count={2} width={'100%'} height={15} duration={2}/>
          </p>
      </SkeletonTheme>


      <Skeleton count={1} duration={1} width={100} height={100} inline={true}/>
      <Skeleton count={1} duration={1} width={300} height={20} inline={true} />
    </>
  );
};

export default MySkeleton;