import React, {useState, useEffect} from 'react';
import {Link, useSearchParams, useNavigate} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import "react-loading-skeleton/dist/skeleton.css";
import './Search.css';



const axios = require('axios');


const Search = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [isLoading, setIsLoading] = useState(true);
   const [searchData, setSearchData] = useState(null);
   const query = searchParams.get("q")
   const [newQuery, setNewQuery] = useState('')

   const navigate = useNavigate();

   const handleOnsubmit = (e) => {
      e.preventDefault();
      if(newQuery != ''){
         //navigate("/search?q=" + newQuery)
         setIsLoading(true)
         setSearchParams("q="+newQuery)
      }
   }

   useEffect(() => {
      axios.get('https://mutasi.online/lirik/backend/search.php?query='+query)
      .then(function (response) {
         // handle success
         if(response.data.status == 'success'){
            console.log(response.data);
            setSearchData(response.data)
            setIsLoading(false);
         }else{
            alert(response.data.message);
         }
      })
      .catch(function (error) {
         // handle error
         alert('unknown error');
         console.log(error);
      })
   }, [query])

   const handleQueryChange = (e) => {
      setNewQuery(e.target.value)
      if(e.target.value.length >= 2){
         //autoCompleteQuery();
      }

      if(e.target.value == ''){
         //setAutoComplete(null)
      }
   }



   return (
      <>
         <div className="searchBox2">
            <form onSubmit={handleOnsubmit}>
               <input type={'text'} defaultValue={query} onKeyUp={handleQueryChange}/>
               <input type={'submit'} value="Cari Lirik"/>
            </form>
         </div>
         <div className="searchResultContainer">
         {isLoading 
            ? (
               <>
                  <div className='searchResultList'>
                     <div className='image'>
                        <Skeleton count={1} duration={1} width={80} height={80} inline={true}/>
                     </div>
                     <div className='info'>
                        <Skeleton count={1} duration={1} width={'50%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'75%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'40%'} height={15} />
                     </div>
                  </div>
                  <div className='searchResultList'>
                     <div className='image'>
                        <Skeleton count={1} duration={1} width={80} height={80} inline={true}/>
                     </div>
                     <div className='info'>
                        <Skeleton count={1} duration={1} width={'50%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'75%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'40%'} height={15} />
                     </div>
                  </div>
                  <div className='searchResultList'>
                     <div className='image'>
                        <Skeleton count={1} duration={1} width={80} height={80} inline={true}/>
                     </div>
                     <div className='info'>
                        <Skeleton count={1} duration={1} width={'50%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'75%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'40%'} height={15} />
                     </div>
                  </div>
                  <div className='searchResultList'>
                     <div className='image'>
                        <Skeleton count={1} duration={1} width={80} height={80} inline={true}/>
                     </div>
                     <div className='info'>
                        <Skeleton count={1} duration={1} width={'50%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'75%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'40%'} height={15} />
                     </div>
                  </div>
                  <div className='searchResultList'>
                     <div className='image'>
                        <Skeleton count={1} duration={1} width={80} height={80} inline={true}/>
                     </div>
                     <div className='info'>
                        <Skeleton count={1} duration={1} width={'50%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'75%'} height={15} /><br />
                        <Skeleton count={1} duration={1} width={'40%'} height={15} />
                     </div>
                  </div>  
               </>
            ) : (
               <>
                  {searchData != null && searchData.tracks.map((item) => {
                     return (
                        <>
                           <li className='searchResultList' key={Math.random()}>
                              <div className='image'>
                                 <img alt='image' src={item.thumbnails[0].url} onError={(e) => {e.target.style.display='none'}}/>
                              </div>
                              <div className='info'>
                                 <Link to={'/detail/' + item.videoId}>{item.title}</Link><br/>
                                 Artist: {item.artistName}<br/>
                                 Duration: {item.duration}
                              </div>
                           </li>
                        </>
                     );
                  })}
               </>
            )
         }
         </div>
      </>
   )
}

export default Search