import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './Home.css';

const axios = require('axios');

const Home = () => {
   const [query, setQuery] = useState('');
   const [autoComplete, setAutoComplete] = useState(null);
   

   const navigate = useNavigate();
   
   const handleSubmit = (e) => {
      e.preventDefault();
      if(query != ''){
         navigate("/search?q=" + query)
      }
   }

   const handleQueryChange = (e) => {
      setQuery(e.target.value)
      if(e.target.value.length >= 2){
         autoCompleteQuery();
      }

      if(e.target.value == ''){
         setAutoComplete(null)
      }
   }

   async function autoCompleteQuery() {
      try {
         //const url = "http://localhost/autocomplete.php?query=" + encodeURI(query);
         const url = "https://mutasi.online/lirik/backend/autocomplete.php?query=" + encodeURI(query);
         const response = await axios.get(url);
         setAutoComplete(response.data.keywords)
         console.log(response.data);
      } catch (error) {
         console.error(error);
      }
   }

   return (
      <>
         <div className='searchBox'>
            <form onSubmit={handleSubmit}>
               <input type={'text'} placeholder="Egg. Story of my love" onKeyUp={handleQueryChange} />
               <input type={'submit'} value='Cari Lirik' />
            </form>
            <div className='resultWrapper'>
               <ul className='resultData'>
               {autoComplete &&
                  autoComplete.map((item) => {
                     return <li key={item}><Link to={'/search?q='+item}>{item}</Link></li>;
                  })
               }
               </ul>
            </div>
         </div>
      </>
   );
};

export default Home;